export class Pagination {
    // Constructor arguments are different than body params
    // due to receiving constructor argument style from API. The
    // body style would be preferred...
    constructor({
        page = 1,
        per_page = 15,
        count = 0
    } = {}) {
        this.page = parseInt(page);
        this.totalRecords = parseInt(count);
        this.pageSize = parseInt(per_page);
    }
}

export default Pagination;
