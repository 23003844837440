<template>
    <div class="mb-2 rounded">
        <div class="box-header">
            <i class="fas fa-comments"></i>
            Activity
        </div>
        <div class="box-body">
            <div>
                <transition-group name="list" mode="out-in" tag="p">
                    <div
                        v-for="comment in comments"
                        :key="comment.id"
                        class="box-content-record"
                    >
                        <router-link :to="link(comment)">{{
                            getTitle(comment)
                        }}</router-link>
                        <span class="box-content-record-info"
                            >{{
                                comment.created | formatTimestamp("fromNow")
                            }}
                            in {{ comment.type }}</span
                        >
                        <span class="box-content-record-author">
                            <router-link
                                :to="{
                                    name: 'user',
                                    params: { id: comment.author.id },
                                }"
                            >
                                {{ comment.author.username }}
                            </router-link>
                        </span>
                    </div>
                </transition-group>
            </div>
        </div>
    </div>
</template>

<script>
import Comment from "@/models/Comment";
import CommentService from "@/services/CommentService";

export default {
    name: "SideBoxLatestComments",
    props: {},
    data() {
        return {
            error: null,
            comments: [],
        };
    },
    mounted() {
        this.getComments();
    },
    methods: {
        getComments() {
            CommentService.getLatestComments()
                .then((response) => {
                    this.comments = response.comments;
                })
                .catch((error) => {
                    this.error = "Could not fetch latest comments.";
                });
        },
        getTitle(comment) {
            let title = "";

            // Shouldn't really happen unless we have malformed data from backend...
            if (!comment.commentable) {
                return title;
            }

            switch (comment.content_type.model) {
                case "blogpost":
                    title = comment.commentable.title;
                    break;
                case "config":
                    title = comment.commentable.title;
                    break;
                case "forumtopic":
                    title = comment.commentable.name;
                    break;
                case "article":
                    title = comment.commentable.title;
                    break;
            }
            return title;
        },
        link(comment) {
            let link = null;

            switch (comment.content_type.model) {
                case "blogpost":
                    link = {
                        name: "blogPost",
                        params: { id: comment.post_id },
                    };
                    break;
                case "config":
                    link = {
                        name: "config",
                        params: { id: comment.post_id },
                    };
                    break;
                case "forumtopic":
                    link = {
                        name: "forumTopic",
                        params: { id: comment.post_id },
                    };
                    break;
                case "article":
                    link = {
                        name: "article",
                        params: { id: comment.post_id },
                    };
                    break;
            }
            return link;
        }
    },
    computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
