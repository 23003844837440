<script>
import store from '@/store'

export default {
    methods: {
        $can(permission) {
            if (permission === null || permission === undefined) {
                throw 'No permission passed for permission check.'
            }
            return this.hasPermission(permission);
        },
        hasPermission(permission) {
            if (this.$user && this.$user.is_superuser) {
                return true;
            }
            for (let i = 0; i < store.getters.permissions.length; i++) {
                if (store.getters.permissions[i].codename === permission) {
                    return true;
                }
            }
            return false;
        }
    },
    computed: {
        $user() {
            return store.getters.user;
        }
    }
}
</script>