import BaseModel from "@/models/BaseModel";

export class ContentType extends BaseModel {
    constructor({
        id,
        model
    } = {}) {
        super();

        this.id = id;
        this.model = model;
    }
}

export default ContentType;
