import Pagination from '@/models/Pagination';
import BaseService from './BaseService';
import { UserRequest, UserResponse, UsersResponse } from './mappers/UserMapper';

class UserService extends BaseService {
    constructor() {
        super();
    }

    activateUser(email, token) {
        return this.post(`users/activate`, { email: email, token: token });
    }

    register(data) {
        return this.post(`register`, data); // TODO: RegisterRequest?
    }

    updateProfile(data) {
        return this.patch(`my/user`, UserRequest(data)).then((response) =>
            UserResponse(response.data)
        );
    }

    getUser(id) {
        return this.get(`users/${id}`).then((response) =>
            UserResponse(response.data)
        );
    }

    getMyUser() {
        return this.get('my/user').then((response) =>
            UserResponse(response.data)
        );
    }

    createUser(data) {
        return this.post(`users`, UserRequest(data)).then((response) =>
            UserResponse(response.data)
        );
    }

    updateUser(id, data) {
        return this.patch(`users/${id}`, UserRequest(data)).then((response) =>
            UserResponse(response.data)
        );
    }

    deleteUser(id) {
        return this.delete(`users/${id}`);
    }

    getUsers(pagination, sorting) {
        let p = pagination;
        let s = sorting;
        return this.get(
            `users?page=${p.page}&per_page=${p.pageSize}&sort=${s.sort}`
        )
            .then((response) => response.data)
            .then((response) => {
                let users = UsersResponse(response.results);
                let pagination = new Pagination(response);

                return { users, pagination };
            });
    }

    getPublicUser(id) {
        return this.get(`public/users/${id}`).then((response) =>
            UserResponse(response.data)
        );
    }

    getPublicUsersBySearch(keyword, pagination, sorting) {
        let p = pagination;
        let s = sorting;
        return this.get(
            `public/users/search?keyword=${keyword}&page=${p.page}&per_page=${p.pageSize}&sort=${s.sort}`
        )
            .then((response) => response.data)
            .then((response) => {
                let users = UsersResponse(response.results);
                let pagination = new Pagination(response);

                return { users, pagination };
            });
    }
}

export default new UserService();