import AuthService from '@/services/AuthService';
import UserService from '@/services/UserService';
import axios from 'axios';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    status: '',
    user: null,
    permissions: [],
    initiated: false
  },
  mutations: {
    initStore(state) {
        axios.defaults.withCredentials = true;

        if (localStorage.getItem('user')) {
            state.user = JSON.parse(localStorage.getItem('user'));
        }
        if (localStorage.getItem('permissions')) {
            state.permissions = JSON.parse(localStorage.getItem('permissions'));
        }
        state.initiated = true;
    },
    authRequest(state) {
        state.status = 'loading';
    },
    authSuccess(state, {user}) {
        state.status = 'success';
        state.user = user;
    },
    authError(state){
        state.status = 'error';
    },
    logout(state){
        state.status = '';
        state.user = '';
        state.permissions = [];
    },
    getPermissionsSuccess(state, {permissions}) {
        state.permissions = permissions;
    },
    updateProfileSuccess(state, {user}) {
        state.user = user;
    }
  },
  actions: {
    login({commit, dispatch}, credentials) {
        commit('authRequest');
        return AuthService.login(credentials.username, credentials.password)
            .then(response => {
                AuthService.getMyUser().then(user => {
                    localStorage.setItem('user', JSON.stringify(user));

                    commit('authSuccess', {user});
                    dispatch('getMyPermissions');
                })

            }).catch(error => {
                commit('authError');
                localStorage.removeItem('user');
            });
    },
    logout({commit, state}) {
        AuthService.logout();
        commit('logout');
        localStorage.clear();
    },
    getMyPermissions({commit}) {
        return AuthService.getMyPermissions().then(response => {
            let permissions = response.data;
            localStorage.setItem('permissions', JSON.stringify(permissions));
            commit('getPermissionsSuccess', {permissions});
        });
    },
    updateProfile({commit}, data) {
        return UserService.updateProfile(data).then(user => {
            localStorage.setItem('user', JSON.stringify(user));
            commit('updateProfileSuccess', {user});
            return user;
        });
    }
  },
  getters: {
    isLoggedIn: state => !!state.user,
    authStatus: state => state.status,
    user: state => state.user,
    permissions: state => state.permissions
  }
});
