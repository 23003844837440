import BaseService from './BaseService';
import { UserResponse } from './mappers/UserMapper';

class AuthService extends BaseService {
    constructor() {
        super();
    }

    login(username, password) {
        return this.post("login", {username, password});
    }

    logout() {
        return this.post("logout");
    }

    getMyUser() {
        return this.get("my/user").then(response => UserResponse(response.data));
    }

    getMyPermissions() {
        return this.get("my/permissions");
    }

    forgotPassword(data) {
        return this.post("forgot-password", data);
    }

    resetPassword(data) {
        return this.post("password-reset", data);
    }
}

export default new AuthService();