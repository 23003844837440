import { User } from '@/models/User';

export const UserRequest = (argUser) => {
  const user = argUser.copy()

  if (!user.id) {
    delete user.id
  }

  user.roles = user.roles.map(r => r.id);

  if (user.profile.nation) {
    user.profile.nation = user.profile.nation.id
  }

  return user
}

export const UserResponse = (user) => {
  return new User(user)
}

export const UsersResponse = (users) => {
  return users.map((a) => UserResponse(a))
}