import BaseModel from "@/models/BaseModel";
import Permission from "@/models/Permission";

export class Role extends BaseModel {
    constructor({
        id,
        name,
        permissions = []
    } = {}) {
        super();

        this.id = id;
        this.name = name;
        this.permissions = permissions.map(p => new Permission(p));
    }
}

export default Role;
