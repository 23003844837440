import { BlogPost } from '@/models/BlogPost';

export const BlogPostRequest = (argBlogPost) => {
  const blogPost = argBlogPost.copy()

  if (!blogPost.id) {
    delete blogPost.id
  }

  if (blogPost.category) {
    blogPost.category = blogPost.category.id
  }

  return blogPost
}

export const BlogPostResponse = (blogPost) => {
  return new BlogPost(blogPost)
}

export const BlogPostsResponse = (blogPosts) => {
  return blogPosts.map((a) => BlogPostResponse(a))
}