
import BlogPost from '../models/BlogPost';
import Pagination from '../models/Pagination';
import BaseService from './BaseService';
import { BlogPostRequest } from './mappers/BlogPostMapper';

class BlogPostService extends BaseService {

    constructor() {
        super();
    }

    getBlogPosts(pagination) {
        let p = pagination
        return this.get(
            `blog-posts?page=${p.page}&per_page=${p.pageSize}&sort=${p.sort}`
        );
    }

    getBlogPost(id) {
        return this.get(`blog-posts/${id}`)
                   .then(response => new BlogPost(response.data));
    }

    deleteBlogPost(id) {
        return this.delete(`blog-posts/${id}`);
    }

    createBlogPost(data) {
        return this.post(`blog-posts`, BlogPostRequest(data)).then(
            response => new BlogPost(response.data)
        );
    }

    updateBlogPost(id, data) {
        return this.patch(`blog-posts/${id}`, BlogPostRequest(data));
    }

    getPublicBlogPost(id) {
        return this.get(`public/blog-posts/${id}`)
                   .then(response => new BlogPost(response.data));
    }

    getPublicPublishedBlogPosts(pagination, sorting) {
        let p = pagination;
        let s = sorting;
        return this.get(`public/blog-posts/published?page=${p.page}&per_page=${p.pageSize}&sort=${s.sort}`)
                    .then(response => response.data)
                    .then(response => {
                        let blogPosts = response.results.map(b => new BlogPost(b));
                        let pagination = new Pagination(response);

                        return { blogPosts, pagination }
                    });
    }

    getPublicPublishedBlogPostsByAuthor(authorId, pagination, sorting) {
        let p = pagination;
        let s = sorting;
        return this.get(
            `public/users/${authorId}/blog-posts/published?page=${p.page}&per_page=${p.pageSize}&sort=${s.sort}`
        )
            .then(response => response.data)
            .then(response => {
                let blogPosts = response.results.map(b => new BlogPost(b));
                let pagination = new Pagination(response);

                return { blogPosts, pagination };
            });
    }
}

export default new BlogPostService();
